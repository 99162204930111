<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-button fill="clear" class="close-button" (click)="dismiss()">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
  <div class="modal-title">
    <h3>Create conversation</h3>
  </div>
</ion-header>

<ion-content>
  <div style="padding: 18px">
    <ion-label> Title </ion-label>
    <ion-textarea
      #markdownInput
      placeholder="e.g. Customer needs help resetting password"
      [(ngModel)]="markdown"
      class="input-field"
    >
    </ion-textarea>

    <div style="margin: 10px 0 5px 0">
      <ion-label> Replies </ion-label>
      <br />
      <ion-button fill="clear" color="dark" (click)="showPrivacyOptions()" class="button-dark-border">
        <ion-icon [name]="type === 'email' ? 'mail' : 'lock-closed-outline'" class="padded-icon button-icon"></ion-icon>
        {{ type === 'email' ? 'Send via email' : 'Private' }}
      </ion-button>
      <ion-spinner
        name="crescent"
        *ngIf="loadingInboxes"
        style="height: 20px; margin-bottom: -10px; margin-left: 5px"
      ></ion-spinner>
      <ion-button
        fill="clear"
        color="dark"
        (click)="showInboxOptions()"
        *ngIf="type === 'email' && selectedEmailInbox"
        style="font-weight: 400"
      >
        From: {{ selectedEmailInbox.email }}
        <ion-icon name="chevron-down-outline" class="icon-padded-left button-icon"></ion-icon>
      </ion-button>
      <ion-button
        fill="clear"
        color="danger"
        (click)="showInboxOptions()"
        *ngIf="type === 'email' && !loadingInboxes && emailInboxes && !emailInboxes.length"
        routerLink="/dashboard/settings/email"
        routerDirection="root"
      >
        <ion-icon name="alert-circle-outline"></ion-icon>
        Configure email inbox first
      </ion-button>
    </div>

    <div style="margin: 10px 0 5px 0" [hidden]="type === 'email'">
      <ion-label> Triage Channel </ion-label>
      <app-channel-selector
        [channel]="triageChannel"
        (channelDidChange)="onTriageChannelChanged($event)"
        [defaultInternalOnly]="true"
      ></app-channel-selector>
    </div>

    <div>
      <div>
        <ion-label> Customer (Optional) </ion-label>
        <br />
        <ion-button fill="clear" color="dark" (click)="selectCustomer()" class="button-dark-border">
          <ion-icon [name]="customer ? 'business' : 'business-outline'" class="padded-icon button-icon"></ion-icon>
          {{ customer?.name ?? 'Select a customer...' }}
          <ion-icon name="chevron-down-outline" class="icon-padded-left button-icon"></ion-icon>
        </ion-button>
      </div>
      <div style="margin-top: 8px">
        <ion-label> Assignee (Optional) </ion-label>
        <br />
        <ion-button fill="clear" color="dark" (click)="selectAssignee()" class="button-dark-border">
          <ion-icon [name]="assignee ? 'person' : 'person-outline'" class="padded-icon button-icon"></ion-icon>
          {{ assignee?.name ?? 'Select an assignee...' }}
          <ion-icon name="chevron-down-outline" class="icon-padded-left button-icon"></ion-icon>
        </ion-button>
        <ion-button fill="clear" color="dark" (click)="assignToMe()" [hidden]="assignee?.id"> Assign to me </ion-button>
      </div>
      <div style="margin-top: 8px">
        <ion-label> Submitter (Optional) </ion-label>
        <br />
        <ion-button fill="clear" color="dark" (click)="selectSubmitter()" class="button-dark-border">
          <ion-icon
            [name]="submitter ? 'paper-plane' : 'paper-plane-outline'"
            class="padded-icon button-icon"
          ></ion-icon>
          {{ submitter?.name ?? 'Select a submitter...' }}
          <ion-icon name="chevron-down-outline" class="icon-padded-left button-icon"></ion-icon>
        </ion-button>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer>
  <div style="padding: 20px">
    <ion-button (click)="submit()" [disabled]="isLoading || loadingInboxes">
      Save and view
      <ion-spinner name="crescent" [hidden]="!isLoading" style="margin-left: 10px"></ion-spinner>
      <ion-icon name="arrow-forward-outline" class="icon-padded-left button-icon" [hidden]="isLoading"></ion-icon>
    </ion-button>
  </div>
</ion-footer>
