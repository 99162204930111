import { CommonModule } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonicModule, ModalController, NavController, PopoverController } from '@ionic/angular';

import { ChannelSelectorModule } from 'src/app/components/channel-selector/channel-selector.module';
import { CONVERSATION_STATUS, routes } from 'src/app/constants';
import { removeUndefinedFields } from 'src/app/utils/utils';
import { Customer, InboundEmailRule, Profile, SlackChannel } from 'src/models';
import { AuthService } from 'src/services/auth.service';
import { ConversationsService } from 'src/services/conversations.service';
import { InboundEmailrulesService } from 'src/services/inbound-email-rules.service';
import { MessageService } from 'src/services/message.service';
import { SearchablePopoverService } from 'src/services/searchable-popover.service';
import { SlackService } from 'src/services/slack.service';

import { ActionDropdownComponent, ActionDropdownType } from '../action-dropdown/action-dropdown.component';

@Component({
  standalone: true,
  imports: [CommonModule, FormsModule, IonicModule, ChannelSelectorModule],
  selector: 'app-create-conversation-modal',
  templateUrl: './create-conversation-modal.component.html',
  styleUrls: ['./create-conversation-modal.component.scss'],
})
export class CreateConversationModalComponent implements OnInit {
  @ViewChild('markdownInput', { static: false }) markdownInput;

  isLoading = false;
  markdown: string;
  type: 'triage' | 'email' = 'triage';
  triageChannel: SlackChannel;
  customer: Partial<Customer>;
  assignee: Partial<Profile>;
  submitter: Partial<Profile>;

  loadingInboxes = false;
  emailInboxes: Partial<InboundEmailRule>[];
  selectedEmailInbox: Partial<InboundEmailRule>;

  constructor(
    private modalCtrl: ModalController,
    private navController: NavController,
    private popoverCtrl: PopoverController,
    private slackService: SlackService,
    private searchablePopoverService: SearchablePopoverService,
    public authService: AuthService,
    private msgSrvc: MessageService,
    private conversationsService: ConversationsService,
    private inboundEmailRulesService: InboundEmailrulesService,
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.markdownInput.setFocus(), 500);

    this.loadTriageChannel();
  }

  async loadTriageChannel() {
    const triageChannel = await this.slackService.getChannel(this.authService.tenant.defaultTriageChannelId);

    if (triageChannel) {
      this.triageChannel = triageChannel;
    }
  }

  selectCustomer() {
    this.searchablePopoverService.customers({
      event,
      selectedCustomerId: this.customer?.id,
      callback: async (id: string, name: string) => {
        this.customer = {
          id,
          name,
        };
      },
    });
  }

  selectAssignee() {
    this.searchablePopoverService.users({
      event,
      selectedUserId: this.assignee?.id,
      callback: async (id: string, name: string) => {
        this.assignee = {
          id,
          name,
        };
      },
    });
  }

  selectSubmitter() {
    this.searchablePopoverService.allUsers({
      event,
      selectedUserId: this.submitter?.id,
      callback: async (id: string, name: string, user: any) => {
        this.submitter = {
          id,
          name,
          email: user.email,
        };
      },
    });
  }

  async showPrivacyOptions() {
    const PRIVATE_LABEL = 'Replies are private in triage channel';
    const EMAIL_LABEL = 'Send replies via email';

    const options: ActionDropdownType[] = [
      {
        label: PRIVATE_LABEL,
        icon: 'lock-closed-outline',
      },
      {
        label: EMAIL_LABEL,
        icon: 'mail-outline',
      },
    ];

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: async (index: number) => {
          popover.dismiss();
          this.type = index === 1 ? 'email' : 'triage';

          if (this.type === 'email') {
            this.loadEmailInboxes();
          }
        },
      },
      showBackdrop: false,
      event,
    });

    popover.present();
  }

  async showInboxOptions() {
    const options: ActionDropdownType[] = this.emailInboxes.map((inbox) => {
      return {
        label: inbox.email,
      };
    });

    const popover = await this.popoverCtrl.create({
      component: ActionDropdownComponent,
      componentProps: {
        options,
        callback: async (index: number) => {
          popover.dismiss();

          this.selectedEmailInbox = this.emailInboxes[index];
        },
      },
      showBackdrop: false,
      event,
    });

    popover.present();
  }

  async loadEmailInboxes() {
    this.loadingInboxes = true;
    const result = await this.inboundEmailRulesService.list({ order: ['createdAt'], descending: false });
    this.emailInboxes = result.data ?? [];

    if (this.emailInboxes.length) {
      this.selectedEmailInbox = this.emailInboxes[0];
    }

    this.loadingInboxes = false;
  }

  assignToMe() {
    this.assignee = this.authService.profile;
  }

  async submit() {
    if (!this.markdown) {
      await this.msgSrvc.show('Whoops! Please enter a title.');

      return;
    }

    this.isLoading = true;

    try {
      const conversation: any = {
        markdown: this.markdown,
        status: CONVERSATION_STATUS.IN_PROGRESS,
        triageChannelId: this.triageChannel?.id,
        customerId: this.customer?.id,
        assignedToUserId: this.assignee?.id,
        type: this.type,
      };

      if (this.submitter) {
        conversation.onBehalfOf = {
          name: this.submitter.name,
          email: this.submitter.email,
          id: this.submitter.id,
        };
      }

      if (this.type === 'email') {
        if (!this.selectedEmailInbox) {
          this.msgSrvc.show('Set up an email inbox first');
          return;
        }

        if (!this.submitter) {
          this.msgSrvc.show('Select a submitter to continue');
          return;
        }

        conversation.emailInboxId = this.selectedEmailInbox.id;
      }

      const result = await this.conversationsService.create(removeUndefinedFields(conversation));

      this.navController.navigateRoot(`/${routes.DASHBOARD}/${routes.INBOX}/all/conversations/${result.id}`);

      this.dismiss();
    } catch (error) {
      await this.msgSrvc.showError(error);
    } finally {
      this.isLoading = false;
    }
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }

  onTriageChannelChanged(slackChannel?: SlackChannel) {
    this.triageChannel = slackChannel;
  }
}
